import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Image from "react-bootstrap/Image";
import Button from "components/form/Button";
import Profile from "components/Layout/Profile";
import UpgradeButton from "components/Layout/UpgradeButton";
import { icons, membershipType, profilePath } from "utils/constants";
import {
  generatePreSignedUrl,
  getDataFromLocalStorage,
  getFilenameFromUrl,
  objectToFormData,
  titleCaseString,
  userTypeByStatus,
} from "utils/helpers";
import {
  setIsCalendar,
  setIsLogout,
  setIsPremiumPopup,
  throwError,
} from "store/slices";
import Notifications from "./Notifications";
import GlobalSearch from "./GlobalSearch";
import Loader from "components/Layout/Loader";
import axios from "axios";
import { saveAs } from "file-saver";

import "./Navbar.scss";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myRef = useRef();
  const { userDetails } = useSelector((state) => ({
    userDetails: state.student.userDetails,
  }));
  const { first_name, profile_photo_path } = userDetails;
  const userData = getDataFromLocalStorage();

  const {
    profile_photo_path: user_profile,
    member_id,
    user_type,
    membership_details = {},
    name,
    membership_plan_id,
    first_name: card_first_name,
    last_name: card_last_name,
    id: user_id,
    personal_details = {},
    educational_details = {},
    institution_details = {},
    company_details = {},
  } = userData;

  const { id: membershipID, expire_date, plan_name } = membership_details;
  const findType = membershipType.find((o) => o.id === user_type)?.type || "";
  let { ug_institution_name, pg_institution_name, phd_institution_name } =
    educational_details;
  const { institution_name } = institution_details;
  const { company_name } = company_details;

  let instituteValue =
    phd_institution_name ||
    pg_institution_name ||
    ug_institution_name ||
    institution_name ||
    company_name;

  const data = {
    membership: [2, 11].includes(membership_plan_id)
      ? "MEMBERSHIP"
      : "PREMIUM MEMBERSHIP",
    number: member_id,
    bio: instituteValue,
    memberType: titleCaseString(findType),
    from: moment(membership_details?.start_date).format("DD/MM/YYYY"),
    to: moment(membership_details?.expire_date).format("DD/MM/YYYY"),
    nationality:
      personal_details?.country_name ||
      institution_details?.country_name ||
      company_details?.company_country_name ||
      "",
    dateOfIssue: moment(membership_details?.start_date).format("DD/MM/YYYY"),
    cert_mem: "-",
    date: moment(membership_details?.expire_date).format("MM/YY"),
    name: `${card_first_name?.toUpperCase()} ${card_last_name?.toUpperCase()}`,
    certificateUserName: `${card_first_name} ${card_last_name}`,
    type: `${findType.toUpperCase()} MEMBER`,
    profile: user_profile
      ? `https://dashboard-iferp-in.s3.ap-south-1.amazonaws.com/Profile/${user_profile}`
      : "",
    apiURL:
      "https://iferpmembership.in/membership/fellowship/examples/membershipcard.php",
    apiURLCertificate:
      "https://iferpmembership.in/membership/fellowship/examples/newpremium.php",
    apiHeader: {
      "Content-Type": "application/json",
      Accept: "application/pdf",
    },
  };
  const {
    profile,
    membership,
    number,
    date,
    name: card_user_name,
    certificateUserName,
    bio,
    memberType,
    from,
    to,
    nationality,
    dateOfIssue,
    cert_mem,
    type,
    apiURL,
    apiHeader,
    apiURLCertificate,
  } = data;
  const [isProfile, setIsProfile] = useState(false);
  const [isCardLoader, setIsCardLoader] = useState(false);
  const [isCertificateLoader, setIsCertificateLoader] = useState(false);
  const [profileURL, setProfileURL] = useState("");

  const handleRedirect = (e) => {
    setIsProfile(false);
    navigate(`/${userTypeByStatus()}/change-password`);
  };
  const handleClickOutside = (e) => {
    if (myRef.current && !myRef.current.contains(e.target)) {
      setIsProfile(false);
    }
  };

  const downloadCard = () => {
    let payload = objectToFormData({
      member_type: membership,
      user_profile: profileURL,
      user_id: user_id,
      membership_id: number,
      valid_to: date,
      user_name: card_user_name,
      user_type: type,
      submit: "1",
    });

    axios
      .post(apiURL, payload, {
        responseType: "arraybuffer",
        headers: apiHeader,
      })
      .then(async (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        saveAs(url, `${card_first_name.toLowerCase()}-membership-card.pdf`);
        setIsCardLoader(false);
      })
      .catch(() => {
        setIsCardLoader(false);
        dispatch(
          throwError({
            message: "Something went wrong with membership card!",
          })
        );
      });
  };

  const downloadCertificate = () => {
    let payload = objectToFormData({
      user_name: certificateUserName,
      institute_name: bio,
      member_type: memberType,
      valid_from: from,
      valid_to: to,
      membership_id: membershipID,
      nationality: nationality,
      date_of_issue: dateOfIssue,
      cert_mem: cert_mem,
      submit: "1",
    });

    axios
      .post(apiURLCertificate, payload, {
        responseType: "arraybuffer",
        headers: apiHeader,
      })
      .then(async (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        saveAs(url, `${first_name.toLowerCase()}-membership-certificate.pdf`);
        setIsCertificateLoader(false);
      })
      .catch(() => {
        setIsCertificateLoader(false);
        dispatch(
          throwError({
            message: "Something went wrong with membership card!",
          })
        );
      });
  };

  const getPresignProfile = async () => {
    let retunURL = "";
    const response = await generatePreSignedUrl(
      getFilenameFromUrl(profile),
      profilePath
    );
    retunURL = response;
    setProfileURL(retunURL);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  useEffect(() => {
    if (profile) {
      getPresignProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const access = {
    isUpgradeButton:
      !window.location.pathname.includes("upgrade") &&
      ((user_type === "2" && membershipID === 2) ||
        (user_type === "5" && membershipID === 11)),
    isPremiumIcon:
      (membershipID === 3 || membershipID === 12) &&
      !window.location.pathname.includes("renew"),
    isRenew:
      moment().diff(expire_date, "days") > 0 &&
      !window.location.pathname.includes("renew"),
    isMyProfile: !["0", "6"].includes(user_type),
    isCertificate: ![2, 11].includes(membershipID),
  };
  return (
    <nav id="Navbar-container" className="navbar">
      <div className="row w-100 header-parent d-flex align-items-center position-relative">
        <div className="col-xl-5 col-md-4 col-sm-12 cmb-10 center-flex">
          <GlobalSearch placeholder="Search event here" />
        </div>
        <div className="col-xl-7 col-md-8 col-sm-12 cmb-10">
          <div className="row header-child">
            <div className="col-xl-5 col-md-4 col-6  center-flex gap-md-4 gap-2 notification-calender-block">
              <div className="box-shadow icon-content">
                <Image
                  src={icons.calendar}
                  height={24}
                  width={24}
                  className="pointer"
                  onClick={() => {
                    // navigate(`/${findType}/calendar`);
                    dispatch(setIsCalendar(true));
                  }}
                />
              </div>
              <div className="box-shadow icon-content">
                <Notifications />
              </div>
            </div>
            <div className="col-xl-7 col-md-8 col-6  d-flex justify-content-end align-items-center">
              <div className="me-3">
                <div className="color-cetacean-blue text-16-500">
                  {name ? name?.split(" ")[0] : first_name}
                </div>
                <div className="text-10-400">
                  <span className="color-dark-charcole">ID: {member_id} </span>
                </div>
                {plan_name && (
                  <div className="text-10-400">
                    <span className="color-dark-charcole">
                      Plan: {plan_name}
                    </span>
                  </div>
                )}
              </div>
              <div className="me-3 position-relative">
                <Profile
                  isRounded
                  isS3UserURL={profile_photo_path}
                  url={profile_photo_path}
                  text={first_name}
                  size="s-60"
                />

                {access.isPremiumIcon && !access.isRenew && (
                  <div className="profile-indicator">
                    <Image src={icons.primaryCrown} />
                  </div>
                )}
              </div>
              <div className="position-relative">
                <i
                  className="bi bi-chevron-down pointer"
                  onClick={() => {
                    setIsProfile(true);
                  }}
                />
                {isProfile && (
                  <div
                    className="my-profile-block cps-16 cpe-16 cpt-16 cpb-16"
                    ref={myRef}
                  >
                    {access.isMyProfile && (
                      <>
                        <div
                          className="text-15-400 color-black-olive pointer"
                          onClick={() => {
                            setIsProfile(false);
                            navigate(`/${findType}/my-details`);
                          }}
                        >
                          <img
                            src={icons.profile}
                            alt="olgout"
                            className="me-2 h-21"
                          />
                          My profile
                        </div>
                        <hr className="gray-border unset-p unset-m cmt-16 cmb-16" />
                      </>
                    )}

                    {access.isMyProfile && (
                      <>
                        <div
                          className="text-15-400 color-black-olive pointer align-items-center d-flex gap-2"
                          onClick={() => {
                            setIsCardLoader(true);
                            downloadCard();
                          }}
                        >
                          <i className="bi bi-arrow-down" />
                          Membership Card
                          {isCardLoader ? <Loader /> : ""}
                        </div>
                        <hr className="gray-border unset-p unset-m cmt-16 cmb-16" />
                      </>
                    )}

                    {access.isMyProfile && (
                      <>
                        <div
                          className="text-15-400 color-black-olive pointer align-items-center d-flex gap-2"
                          onClick={() => {
                            if (access.isCertificate) {
                              setIsCertificateLoader(true);
                              downloadCertificate();
                            } else {
                              dispatch(setIsPremiumPopup(true));
                            }
                          }}
                        >
                          <i className="bi bi-arrow-down"></i>
                          Membership Certificate
                          {isCertificateLoader ? (
                            <Loader />
                          ) : (
                            !access.isPremiumIcon &&
                            !access.isRenew && (
                              <Image src={icons.primaryCrown} />
                            )
                          )}
                        </div>
                        <hr className="gray-border unset-p unset-m cmt-16 cmb-16" />
                      </>
                    )}
                    <div
                      className="text-15-400 color-black-olive pointer"
                      onClick={handleRedirect}
                    >
                      <img
                        src={icons.lock}
                        alt="olgout"
                        className="me-2 h-21"
                      />
                      Change Password
                    </div>
                    <hr className="gray-border unset-p unset-m cmt-16 cmb-16" />
                    <div
                      className="text-15-400 color-black-olive pointer"
                      onClick={() => {
                        dispatch(setIsLogout(true));
                      }}
                    >
                      <img
                        src={icons.logout}
                        alt="olgout"
                        className="me-2 h-21"
                      />
                      Log Out
                    </div>
                  </div>
                )}
              </div>

              <div className="d-sm-block d-none">
                {access.isRenew ? (
                  <Button
                    isRounded
                    text="Renew"
                    btnStyle="primary-outline"
                    className="ms-sm-3 ms-0 mt-sm-0 mt-3 "
                    onClick={() => {
                      navigate(`/${findType}/renew`);
                    }}
                  />
                ) : (
                  access.isUpgradeButton && (
                    <div className="ms-sm-3 ms-0 mt-sm-0 mt-3">
                      <UpgradeButton />
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="d-sm-none d-block">
              {access.isRenew ? (
                <Button
                  isRounded
                  text="Renew"
                  btnStyle="primary-outline"
                  className="ms-sm-3 ms-0 mt-sm-0 mt-3 "
                  onClick={() => {
                    navigate(`/${findType}/renew`);
                  }}
                />
              ) : (
                access.isUpgradeButton && (
                  <div className="ms-sm-3 ms-0 mt-sm-0 mt-3">
                    <UpgradeButton />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
