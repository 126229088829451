import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Button from "components/form/Button";
import {
  objectToFormData,
  titleCaseString,
  trimLeftSpace,
} from "utils/helpers";
import TextArea from "components/form/TextArea";
import { useDispatch } from "react-redux";
import {
  addEditComments,
  handleStoreRemarks,
  throwSuccess,
} from "store/slices";
import "./ReplayMessageBlock.scss";

const ReplayMessageBlock = ({
  icon,
  comment,
  viewType,
  elem,
  handelSuccess,
  showMessageIndicator,
  isCampaign,
}) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [currentView, setCurrentView] = useState(viewType);
  const [commentVal, setCommentVal] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const toggleDropdown = () => {
    setShow(!show);
  };

  const handlePencilClick = (value) => {
    setCommentVal(value);
    setCurrentView("message");
  };

  const handleComment = async () => {
    setIsLoading(true);
    const payload = {
      comment: commentVal,
      user_id: elem?.id,
    };
    let response = "";
    if (isCampaign) {
      response = await dispatch(
        handleStoreRemarks(
          objectToFormData({ remark: commentVal, id: elem?.compaing_id })
        )
      );
    } else {
      response = await dispatch(addEditComments(objectToFormData(payload)));
    }
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      setShow(false);
      if (!comment) {
        setCommentVal("");
      }
      handelSuccess();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (comment) {
      setCommentVal(comment);
    }
  }, [comment]);

  return (
    <Dropdown drop={"start"} show={show} onToggle={toggleDropdown}>
      <Button
        btnStyle="unset-primary"
        icon={icon}
        onClick={toggleDropdown}
        isSquare
      />

      {showMessageIndicator && (elem?.comment || elem?.remark) && (
        <span className="message-indicator bg-4646"></span>
      )}

      <Dropdown.Menu align="end" className="dropdown-menu-custom">
        <div className="cps-20 cpe-20 cpt-15 cpb-15">
          {currentView === "view" && (
            <div className="d-flex align-items-lg-start justify-content-between">
              <div className="text-wrap">
                {comment ? titleCaseString(comment) : "No any comment!!!"}
              </div>
              <div className="d-flex gap-3">
                <i
                  className="bi bi-pencil-square pointer"
                  onClick={() => {
                    handlePencilClick(comment);
                  }}
                />
                <i
                  className="bi bi-x-lg pointer"
                  onClick={() => {
                    setShow(false);
                  }}
                />
              </div>
            </div>
          )}

          {currentView === "message" && (
            <>
              <div className="d-flex justify-content-between cmb-10">
                <span>{isCampaign ? "Remark" : "Comment"}</span>
                <i
                  className="bi bi-x-lg pointer"
                  onClick={() => {
                    setShow(false);
                    setCurrentView(viewType);
                    setCommentVal("");
                  }}
                />
              </div>
              <div className="cmb-10">
                <TextArea
                  value={commentVal}
                  rows={3}
                  labelClass="text-15-400-18 color-black-olive"
                  onChange={(e) => {
                    setCommentVal(trimLeftSpace(e.target.value));
                  }}
                />
              </div>
              <div className="d-flex align-items-center justify-content-center gap-2 flex-wrap">
                <Button
                  btnStyle="light-outline"
                  text="Cancel"
                  className="mw-100 max-w-100"
                  onClick={() => {
                    setShow(false);
                    setCommentVal("");
                  }}
                />
                <Button
                  btnStyle="primary-dark"
                  text={comment ? "Update" : "Add"}
                  className="mw-100 max-w-100"
                  onClick={handleComment}
                  btnLoading={isLoading}
                />
              </div>
            </>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ReplayMessageBlock;
