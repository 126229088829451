const commonRoute = {
  researchProfile: "/member/global-research-profile/:id",
  globalPost: "/member/global-post/:postID",
  login: "/:type/login",
  changePassword: "/:type/change-password",
  myProfile: "/:type/my-details",
  forgotPassword: "/:type/forgot-password",
  signup: "/member/register",
  verifyEmail: "/member/verify-email",
  paymentPage: "/member/cc-avenue-payment",
  register: "/:type/member/register",
  registerDetails: "/:memberType/register/:userId/:formType",
  calendar: "/:memberType/calendar",
  myProfileType: "/:memberType/my-profile/:type",
  myProfileEvent: "/:memberType/my-profile/my-events/:eventId",
  publications: "/:memberType/publications/:publicationType",
  publicationAssistance: "/:memberType/publications/publicationAssistance",
  publicationSubmitPaperForm:
    "/:memberType/publications/:publicationType/submit-paper/:journalId",
  chaptersAndGroupsType: "/:memberType/chapters-groups/:type",
  chaptersDetailsType: "/:memberType/chapters-groups/chapters/:chapterId/:type",
  chaptersDetailsPeople:
    "/:memberType/chapters-groups/chapters/:chapterId/:type/:uId",
  groupDetails: "/:memberType/:moduleType/sig-groups/:groupId/:eventType",
  careerSupport: "/:memberType/career-support/:type",
  careerSupportView:
    "/:memberType/career-support/applied-career-support/:type/:careerId",
  careerSupportForm: "/:memberType/career-support/:careerId/:formType",
  careerEvents: "/:memberType/:careerType/:careerId/events",
  keynoteSpeakerView:
    "/:memberType/career-support/applied-career-support/keynote-speaker",
  keynoteSpeakerViewType:
    "/:memberType/career-support/applied-career-support/keynote-speaker/:pType",
  applicationDetailsView:
    "/:memberType/career-support/applied-career-support/keynote-speaker/speaker-applications/:id",
  viewInvitationDetails:
    "/:memberType/career-support/applied-career-support/keynote-speaker/invitation-received/:id",
  videoRecord:
    "/:memberType/career-support/applied-career-support/video-record",
  mentorship: "/:memberType/mentorship/mentee/:type",
  mentorshipMentor: "/:memberType/mentorship/mentor",
  mentorBookSession: "/:memberType/mentorship/mentee/book-session/:sId",
  cardInformation: "/:memberType/mentorship/mentee/cardInformation/:sId",
  singleMenteeDetails: "/:memberType/mentorship/mentor/details/:id",
  addNewSession: "/professional/mentorship/mentor/:formType",
  sessionDetail: "/professional/mentorship/mentor/session/details/:sId",
  mentorProfileDetail: "/:memberType/mentorship/mentor/profile/:id",

  nominateForAward: "/:memberType/nominate-for-award",
  nominateForAwardList: "/:memberType/nominate-for-award-list",
  certificatesAndRewards: "/:memberType/certificates-and-rewards",
  certificatesAndRewardsList: "/:memberType/certificates-and-rewards-list",
  awardWinners: "/:memberType/award-winners",
  helpSupport: "/:memberType/help/help-and-support",
  myPersonalExecutive: "/:memberType/help/my-personal-executive",
  inboxNotifications: "/:memberType/inbox-notifications/:type",
  emailDetails: "/:memberType/inbox-notifications/:type/:emailId/:emailKey",
  emailForm: "/:memberType/inbox-notifications/:type/:emailId/upload-documents",
  networkManagementParent: "/:memberType/network-management/:type",
  networkManagement: "/:memberType/network-management/:type/:pType/:cType",
  createGroup: "/:memberType/network-management/:type/group/:groupId",
  digitalLibrary: "/:memberType/digital-library",
  profileManagement: "/:memberType/profile-management",
  journalManagement: "/:memberType/journal-management",
  journalManagementType: "/:memberType/journal-management/:type",
  eventManagementType: "/:memberType/:moduleType/:type",
  eventDetailsType: "/:memberType/:moduleType/event-details/:eventId/:formType",
  addEvent: "/:memberType/:moduleType/events/:eventId",
  eventAgandaForm:
    "/:memberType/:moduleType/event-details/:eventId/agenda/:agandaId",
  eventSessionDetails:
    "/:memberType/:moduleType/event-details/:eventId/session/:sessionId",
  eventPaper: "/:memberType/:moduleType/submitted-papers/:paperId",
  activityPlan: "/:memberType/:moduleType/activity-plan/:planType/:planId",
  eventReviewerDetails: "/:memberType/event-management/reviewers/:reviewerId",
  journalReviewerDetails:
    "/:memberType/journal-management/reviewers/:reviewerId",
  conferencesAndEvents: "/:memberType/:moduleType/event-list/:eventType",
  conferencesAndEventsList:
    "/:memberType/:moduleType/:eventUserType/event-list/:eventType",
  abstractSubmissionForm:
    "/:memberType/:moduleType/:eventType/:eventId/abstract-submission/:abstractId",
  applyForCommitteeMember:
    "/:memberType/:moduleType/apply-for-committee-member",
  submittedAbstract: "/:memberType/:moduleType/submitted-abstract",
  sponsorshipnForm: "/:memberType/:moduleType/:eventType/:eventId/sponsorship",
  nominateForm: "/:memberType/:moduleType/:eventType/:eventId/nominate",
  upgradeUser: "/:memberType/upgrade",
  renewUser: "/:memberType/renew",
  certificateManagementType: "/:memberType/certificate-management/:type",
  certificateManagementEventDetail:
    "/:memberType/certificate-management/event-certificates/:eventID",
  speakerDetails: "/:memberType/:moduleType/speaker/:id",
  committeeMemberDetails: "/:memberType/:moduleType/committee-member/:id",
  postDetails: "/:memberType/:moduleType/network/post/post-details/:postID",
  userReasearchprofile: "/:memberType/user-profile/research-profile/:userID",
  emailRedirection: "/member/email-redirect",
  regionManagementType: "/:memberType/region-management/:rType",
  speakerInvitation: "/professional/member/speaker-invitation/register",
  campaignSignInWithOTP: "/member/campaign/register",
  unSubscribe: "/unsubscribe",
};

const adminRoute = {
  dashboard: "/admin/dashboard",
  userActivityManagement: "/admin/activity-management",
  userActivityManagementType: "/admin/activity-management/:type",
  profileManagement: "/admin/profile-management",
  eventManagement: "/admin/event-management",
  journalManagement: "/admin/journal-management",
  chaptersAndGroups: "/admin/chapters-groups",
  chapters: "/admin/chapters-groups/chapters",
  addChapters: "/admin/chapters-groups/chapters/:chapterId",
  certificateManagement: "/admin/certificate-management",
  awardManagement: "/admin/award-management",
  awardManagementType: "/admin/award-management/:type",
  awardWinnerForm: "/admin/award-management/award-winners/:awardWinnerId",
  resourceManagement: "/admin/resource-management",
  resourceManagementForm:
    "/admin/resource-management/manage-resource/:resourceId",
  viewResource: "/admin/resource-management/:resourceId",
  careerManagement: "/admin/career-management",
  careerManagementType: "/admin/career-management/:type",
  careerManagementForm: "/admin/career-management/career-support/:careerId",
  careerEventMembers: "/admin/career-management/:careerId/events/:eventId",
  careerSupportView:
    "/admin/career-management/applied-career-support/:type/:careerId",
  KeynoteSpeaker: "/admin/career-management/6/keynote-speaker",
  KeynoteSpeakerType: "/admin/career-management/:uId/keynote-speaker/:type",
  sendInvitationDetails:
    "/admin/career-management/keynote-speaker/sent-invitation/:id",
  mentorshipManagement: "/admin/mentorship-management",
  mentorshipManagementType: "/admin/mentorship-management/:type",
  detailsOfMentor: "/admin/mentorship-management/details/:mId",
  detailsOfMentorType: "/admin/mentorship-management/details/:type",
  singleMenteeDetails:
    "/admin/mentorship-management/mentor/mentees/details/:id",
  mainMenteeDetail: "/admin/mentorship-management/mentees/details/:id",
  mentorSettlementDetails:
    "/admin/mentorship-management/payment/mentor-settlement/details/:id",
  menteeSettlementsDetails:
    "/admin/mentorship-management/payment/mentee-settlement/details/:id",

  viewMoeDetailsPayment:
    "admin/mentorship-management/payment/mentor-settlement/details/:sType/detail/:vId",
  brandingManagement: "/admin/branding-management",
  brand: "/admin/branding-management/:brandId",
  brandIndustry: "/admin/branding-management/:brandId/:industryId",
  inboxNotifications: "/admin/inbox-notifications",
  reports: "/admin/reports",
  reportsType: "/admin/reports/:type",
  reportsCountryWise: "/admin/reports/overall-report/:type",
  networkManagement: "/admin/network-management",
  universityManagement: "/admin/university-management",
  institutionManagement: "/admin/institution-management",
  regionManagement: "/admin/region-management",
  departmentManagement: "/admin/department-management",
  departmentManagementType: "/admin/department-management/:type",
  setting: "/admin/settings",
  newsLetter: "/admin/news-letter",
};
const studentRoute = {
  dashboard: "/student/dashboard",
  calendar: "/student/calendar",
  liveEvents: "/student/dashboard/live-events",
  myProfile: "/student/my-profile",
  conferencesAndEvents: "/student/conferences-and-events",
  publications: "/student/publications",
  chaptersAndGroups: "/student/chapters-groups",
  careerSupport: "/student/career-support",
  mentorship: "/student/mentorship/mentee",
  mentorshipMentor: "/student/mentorship/mentor",
  mentorBookSession: "/student/mentorship/mentor/book-session",
  nominateForAward: "/student/nominate-for-award",
  certificatesAndRewards: "/student/certificates-and-rewards",
  awardWinners: "/student/award-winners",
  helpSupport: "/student/help/help-and-support",
  myPersonalExecutive: "/student/help/my-personal-executive",
  networkManagement: "/student/network-management/network",
  inboxNotifications: "/student/inbox-notifications",
  digitalLibrary: "/student/digital-library",
};
const profetionalRoute = {
  dashboard: "/professional/dashboard",
  liveEvents: "/professional/dashboard/live-events",
  myProfile: "/professional/my-profile",
  conferencesAndEvents: "/professional/conferences-and-events",
  publications: "/professional/publications",
  networkManagement: "/professional/network-management/network",
  inboxNotifications: "/professional/inbox-notifications",
  chaptersAndGroups: "/professional/chapters-groups",
  careerSupport: "/professional/career-support",
  mentorship: "/professional/mentorship/mentee",
  mentorshipMentor: "/professional/mentorship/mentor",
  mentorBookSession: "/professional/mentorship/mentor/book-session",
  nominateForAward: "/professional/nominate-for-award",
  certificatesAndRewards: "/professional/certificates-and-rewards",
  digitalLibrary: "/professional/digital-library",
  awardWinners: "/professional/award-winners",
  helpSupport: "/professional/help/help-and-support",
  chatWithMentee:
    "/professional/mentorship/mentee/my-submissions/chat/:id/:sId",
  chatWithMentor:
    "/professional/mentorship/mentor/my-submissions/chat/:id/:sId",
};
const institutionalRoute = {
  dashboard: "/institutional/dashboard",
  myProfile: "/institutional/my-profile",
  conferencesAndEvents: "/institutional/conferences-and-events",
  publications: "/institutional/publications",
  activityPlan: "/institutional/activity-plan",
  activityPlanType: "/institutional/activity-plan/:type",
  institutionalEventForm:
    "/institutional/activity-plan/institutional-plan/add-event",
  institutionalEditEventForm:
    "/institutional/activity-plan/institutional-plan/edit-event/:eventId",
  ourAcademies: "/institutional/our-academicians",
  ourAcademiesType: "/institutional/our-academicians/:type",
  networkManagement: "/institutional/network-management/network",
  inboxNotifications: "/institutional/inbox-notifications",
  innovationAmbassadorType: "/institutional/innovation-ambassador/:type",
  innovationAmbassador: "/institutional/innovation-ambassador",
  chaptersAndGroups: "/institutional/chapters-groups",
  careerSupport: "/institutional/career-support",
  careerEventMembers: "/institutional/career-support/:careerId/events/:eventId",
  nominateForAward: "/institutional/nominate-for-award-list",
  certificatesAndRewardsList: "/institutional/certificates-and-rewards-list",
  collaboration: "/institutional/help/collaboration",
  eventCollaboration: "/institutional/help/collaboration/:eventType/:eventID",
  fundsAndGrants: "/institutional/help/funds-and-grants",
  fundsAndGrantsType: "/institutional/help/funds-and-grants/:type",
  helpSupport: "/institutional/help/help-and-support",
  liveEvents: "/institutional/dashboard/live-events",
};
const corporateRoute = {
  dashboard: "/corporate/dashboard",
  myProfile: "/corporate/my-profile",
  events: "/corporate/events",
  publications: "/corporate/publications",
  brandingAndPublicity: "/corporate/branding-and-publicity",
  brandingAndPublicityForm: "/corporate/branding-and-publicity/:type",
  networkManagement: "/corporate/network-management/network",
  inboxNotifications: "/corporate/inbox-notifications",
  chaptersAndGroups: "/corporate/chapters-groups",
  careerSupport: "/corporate/career-support",
  certificatesAndRewardsList: "/corporate/certificates-and-rewards-list",
  awardWinners: "/corporate/award-winners",
  nominateForAward: "/corporate/nominate-for-award-list",
  digitalLibrary: "/corporate/digital-library",
  helpSupport: "/corporate/help/help-and-support",
};
const resourceRoute = {
  dashboard: "/resource/dashboard",
  profileManagement: "/resource/profile-management",
  eventManagement: "/resource/event-management",
  journalManagement: "/resource/journal-management",
  chaptersAndGroups: "/resource/chapters-groups",
  networkManagement: "/resource/network-management",
  inboxNotifications: "/resource/inbox-notifications",
  reports: "/resource/reports",
  reportsType: "/resource/reports/:type",
  reportsCountryWise: "/resource/reports/overall-report/:type",
  certificateManagement: "/resource/certificate-management",
  awardManagement: "/resource/award-management",
  awardManagementType: "/resource/award-management/:type",
  awardWinnerForm: "/resource/award-management/award-winners/:awardWinnerId",
  brandingManagement: "/resource/branding-management",
  brand: "/resource/branding-management/:brandId",
  brandIndustry: "/resource/branding-management/:brandId/:industryId",
  careerManagement: "/resource/career-management",
  careerManagementType: "/resource/career-management/:type",
  careerManagementForm: "/resource/career-management/career-support/:careerId",
  careerEventMembers: "/resource/career-management/:careerId/events/:eventId",
  careerSupportView:
    "/resource/career-management/applied-career-support/:type/:careerId",
  KeynoteSpeaker: "/resource/career-management/6/keynote-speaker",
  KeynoteSpeakerType: "/resource/career-management/:uId/keynote-speaker/:type",
  sendInvitationDetails:
    "/resource/career-management/keynote-speaker/sent-invitation/:id",
  mentorshipManagement: "/resource/mentorship-management",
  mentorshipManagementType: "/resource/mentorship-management/:type",
  detailsOfMentor: "/resource/mentorship-management/details/:uName",
  detailsOfMentorType: "/resource/mentorship-management/details/:type",
  singleMenteeDetails:
    "/resource/mentorship-management/mentor/mentees/details/:id",
  mainMenteeDetail: "/resource/mentorship-management/mentees/details/:id",
  mentorSettlementDetails:
    "/resource/mentorship-management/payment/mentor-settlement/details/:id",
  menteeSettlementsDetails:
    "/resource/mentorship-management/payment/mentee-settlement/details/:id",
  viewMoeDetailsPayment:
    "resource/mentorship-management/payment/mentor-settlement/details/:sType/detail/:vId",
};
export {
  adminRoute,
  commonRoute,
  studentRoute,
  profetionalRoute,
  institutionalRoute,
  corporateRoute,
  resourceRoute,
};
