import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import CheckBox from "components/form/CheckBox";
import Button from "components/form/Button";
import Card from "components/Layout/Card";
import Table from "components/Layout/Table";
import DeletePopup from "components/Layout/DeletePopup";
import FilterDropdown from "components/Layout/FilterDropdown";
import ExportButton from "components/Layout/ExportButton";
import { getDataFromLocalStorage, objectToFormData } from "utils/helpers";
import { icons } from "utils/constants";
import {
  deleteMembers,
  exportProfile,
  fetchAllProfiles,
  fetchAllResourceProfiles,
  getAllUserTypeReportData,
  resourceCountriesList,
  setIsEditDataOfProfile,
  setIsEditUserProfile,
  throwError,
  throwSuccess,
} from "store/slices";
import AddMember from "./AddMember";
import ProfileDetail from "./ProfileDetail";
import ProfileDeleteRequestPopup from "components/Layout/ProfileDeleteRequestPopup";

const AllProfiles = ({ tabType }) => {
  const params = useParams();
  const { type } = params;
  const isSingleCountryReport = type === "country-wise";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { membershipList, resourceCountryList } = useSelector((state) => ({
    membershipList: state.global.membershipList,
    resourceCountryList: state.global.resourceCountryList,
  }));
  const userType = getDataFromLocalStorage("user_type");
  const [isDeleteRequestId, setIsDeleteRequestId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteLoadingType, setDeleteLoadingType] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userid, setUserid] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [isAddMember, setAddMember] = useState(false);
  const [isDeatils, setDeatils] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableList, setTableList] = useState([]);
  const [searchPayload, setSearchPayload] = useState({
    name: "",
    membership_id_or_type: "",
    email: "",
    phoneno: "",
    join_date: "",
    valid_date: "",
    country: "",
  });
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: 20,
    membership_plan_ids: "",
    is_non_allocated_resource: "",
    tab: "pending_request",
  });
  const [filterDates, setFilterDates] = useState({
    start_date: "",
    end_date: "",
    date_filter: "",
  });
  let newDates = {
    start_date: searchPayload?.start_date
      ? searchPayload?.start_date
      : "" || filterDates?.start_date
      ? moment(filterDates?.start_date).format("YYYY-MM-DD")
      : "",
    end_date: searchPayload?.end_date
      ? searchPayload?.end_date
      : "" || filterDates?.end_date
      ? moment(filterDates?.end_date).format("YYYY-MM-DD")
      : "",
    date_filter: filterDates?.date_filter ? filterDates?.date_filter : "",
  };
  const getProfiles = async (obj) => {
    let forData = "";
    let modifiedObj = { ...obj };

    if (
      obj?.join_date !== "custom" &&
      obj?.valid_date !== "custom" &&
      obj?.start_date &&
      obj?.end_date
    ) {
      delete modifiedObj.start_date;
      delete modifiedObj.end_date;
      forData = objectToFormData(modifiedObj);
    } else {
      forData = objectToFormData(obj);
    }
    let response = null;
    if (isSingleCountryReport) {
      response = await dispatch(getAllUserTypeReportData(forData));
      let resList = [];
      let resResultCount = 0;
      if (response?.data?.data) {
        resList = response?.data?.data || [];
        resResultCount = response?.data?.result_count || 0;
      }
      setTableList(resList);
      setFilterData({
        ...obj,
        total: resResultCount,
      });
    } else {
      if (userType === "6") {
        response = await dispatch(fetchAllResourceProfiles(forData));
      } else {
        response = await dispatch(fetchAllProfiles(forData));
      }
      let resList = [];
      let resResultCount = 0;
      if (response?.data?.users) {
        resList = response?.data?.users || [];
        resResultCount = response?.data?.result_count || 0;
      }
      setTableList(resList);
      setFilterData({
        ...obj,
        total: resResultCount,
      });
    }

    window.scrollTo(0, 0);
    setIsLoading(false);
  };
  const handelChangeFilter = (val) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, membership_plan_ids: val, offset: 0 };
    setFilterData(newData);
    getProfiles(newData);
  };
  const handelChangeSearch = (searchData) => {
    setIsLoading(true);
    let newData = filterData;
    setSearchPayload(searchData);
    newData = { ...newData, ...searchData, offset: 0 };
    setFilterData(newData);
    getProfiles(newData);
  };
  const handelChangePagination = (offset) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, offset: offset };
    setFilterData(newData);
    getProfiles(newData);
  };
  const handelDeleteRequest = async (payload) => {
    setDeleteLoadingType(payload?.status === "1" ? "approve" : "ignore");
    setDeleteLoading(payload?.userid);
    const response = await dispatch(deleteMembers(objectToFormData(payload)));
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      getProfiles({ ...filterData, ...searchPayload });
    } else {
      dispatch(throwError(response?.message));
    }
    setDeleteLoading(false);
    setDeleteLoadingType(false);
  };

  useEffect(() => {
    if (["0", "6"].includes(userType)) {
      let updatedFilterData = { ...filterData, ...searchPayload };
      if (tabType === "0") {
        delete updatedFilterData.tab;
      }
      if (isSingleCountryReport) {
        const urlParams = new URLSearchParams(window.location.search);
        const countryCode = urlParams.get("country_code");
        if (countryCode) {
          delete updatedFilterData.tab;
          getProfiles({ ...updatedFilterData, country_code: countryCode });
        }
      } else {
        getProfiles({ ...updatedFilterData });
      }
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabType, isSingleCountryReport, searchPayload]);

  const fetchResourceCountry = async () => {
    await dispatch(resourceCountriesList());
  };

  useEffect(() => {
    if (userType === "6") fetchResourceCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  const header = [
    {
      isSearch: false,
      searchInputName: "name",
      title: "S.No",
    },
    {
      isSearch: true,
      searchInputName: "name",
      title: "Name",
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      dropdownOptions: {
        options: membershipList,
        key: "name",
        value: "name",
      },
      searchInputName: "membership_id_or_type",
      title: (
        <>
          <div>{"Membership ID"}</div>
          <div>{"& Type"}</div>
        </>
      ),
    },
    {
      isSearch: true,
      searchInputName: "email",
      title: "Email ID",
    },
    {
      isSearch: true,
      searchInputName: "phoneno",
      title: "Phone Number",
    },
    {
      isSearch: isSingleCountryReport ? false : true,
      isResourceCountryFilter: userType === "0" ? false : true,
      handleApplyClick: (e) => {
        setSearchPayload((prev) => {
          return {
            ...prev,
            multiple_countries: e,
          };
        });
      },
      dropdownOptions: {
        options: resourceCountryList,
        key: "id",
        value: "country",
      },
      searchInputName: "country",
      title: <div className="text-nowrap">Country</div>,
    },
    {
      isSearch: true,
      isDatePicker: true,
      searchInputName: "join_date",
      title: "Join Date",
      isProfileManagement: true,
    },
    {
      isSearch: true,
      isDatePicker: true,
      searchInputName: "valid_date",
      title: "Valid Till",
      isProfileManagement: true,
    },
    {
      isSearch: false,
      searchLable: isSingleCountryReport
        ? "Edit/Delete"
        : tabType === "0"
        ? "Edit/Delete"
        : "Approve/Ignore",
      title: "Action",
    },
  ];
  if (tabType === "1") {
    header.splice(header?.length - 1, 0, {
      isSearch: false,
      title: "Comments",
    });
  }
  const rowData = [];
  tableList?.forEach((elem, index) => {
    const {
      id,
      email,
      phone,
      memberId,
      memberType,
      join_date,
      valid_till,
      firstName,
      lastName,
      country,
      reason_of_delete,
    } = elem;
    let isExpired = valid_till && moment().diff(valid_till, "days") > 0;
    let obj = [
      {
        value: <span>{filterData?.offset + index + 1}</span>,
      },
      {
        value: (
          <span
            className="color-new-car pointer text-nowrap"
            onClick={() => {
              setDeatils(true);
              setUserDetail(id);
            }}
          >
            {`${firstName} ${lastName}`}
          </span>
        ),
      },
      {
        value: (
          <span>
            {memberId && <div>{memberId}</div>}
            <div>{memberType}</div>
          </span>
        ),
      },
      {
        value: (
          <div
            style={{
              width: "250px",
              wordBreak: "break-word",
            }}
          >
            {email}
          </div>
        ),
      },
      {
        value: phone,
      },
      {
        value: country || "",
      },
      {
        value: moment(join_date).format("DD.MM.YYYY"),
      },
      {
        value: isExpired
          ? "Expired"
          : valid_till
          ? moment(valid_till).format("DD.MM.YYYY")
          : "-",
      },
      {
        value: (
          <span className="action-icon-buttons">
            {isSingleCountryReport || tabType === "0" ? (
              <>
                <Button
                  btnStyle="light-outline"
                  icon={<img src={icons.edit} alt="edit" />}
                  className="me-2"
                  onClick={() => {
                    setEditData(elem);
                    // setAddMember(true);
                    dispatch(setIsEditUserProfile(true));
                    dispatch(setIsEditDataOfProfile(elem));
                  }}
                  isSquare
                />
                <Button
                  btnStyle="light-outline"
                  icon={<img src={icons.deleteIcon} alt="delete" />}
                  onClick={() => {
                    if (userType === "6") {
                      setIsDeleteRequestId(id);
                    } else {
                      setUserid(id);
                    }
                  }}
                  isSquare
                />
              </>
            ) : (
              <>
                <Button
                  text={"Approve"}
                  btnStyle="primary-dark"
                  className="mw-70 me-2 text-14-400"
                  onClick={() => {
                    handelDeleteRequest({ status: "1", userid: id });
                  }}
                  isSquare
                  btnLoading={
                    deleteLoadingType === "approve" && deleteLoading === id
                  }
                />
                <Button
                  text={"Ignore"}
                  className="mw-70 me-2 text-14-400"
                  btnStyle="danger-outline"
                  onClick={() => {
                    handelDeleteRequest({ status: "0", userid: id });
                  }}
                  isSquare
                  btnLoading={
                    deleteLoadingType === "ignore" && deleteLoading === id
                  }
                />
              </>
            )}
          </span>
        ),
      },
    ];
    if (tabType === "1") {
      obj.splice(obj?.length - 1, 0, {
        value: <span className="text-nowrap">{reason_of_delete}</span> || "-",
      });
    }
    rowData.push({ data: obj });
  });

  return (
    <>
      {isDeleteRequestId && (
        <ProfileDeleteRequestPopup
          id={isDeleteRequestId}
          onHide={() => {
            setIsDeleteRequestId(null);
          }}
          handelSuccess={() => {
            setIsDeleteRequestId(null);
            getProfiles({ ...filterData, ...searchPayload });
          }}
        />
      )}
      {userid && (
        <DeletePopup
          id={userid}
          onHide={() => {
            setUserid(null);
          }}
          handelSuccess={() => {
            setUserid(null);
            getProfiles({ ...filterData, ...searchPayload });
          }}
          handelDelete={async () => {
            let forData = objectToFormData({ userid: userid, status: "1" });
            const response = await dispatch(deleteMembers(forData));
            return response;
          }}
        />
      )}
      {isAddMember && (
        <AddMember
          editData={editData}
          onHide={() => {
            setAddMember(false);
            setEditData(null);
          }}
          handelSuccess={() => {
            setAddMember(false);
            getProfiles({
              ...filterData,
              ...searchPayload,
            });
          }}
        />
      )}
      {isDeatils && (
        <ProfileDetail
          userID={userDetail}
          onHide={() => {
            setDeatils(false);
          }}
        />
      )}
      <Card className="cps-20 cpe-20 cpb-20">
        {isSingleCountryReport ? (
          <div className="table-title cmb-20 cpt-28">
            Profiles ({filterData?.total})
          </div>
        ) : (
          <div
            className={`${
              tabType === "1" ? "cmb-20" : ""
            } d-flex justify-content-between align-items-center flex-wrap cpt-28`}
          >
            <div className="table-title">
              {`${tabType === "0" ? "All Profiles" : "All Pending Requests"}`} (
              {filterData?.total})
            </div>

            {tabType === "0" && (
              <div className="d-flex flex-wrap gap-3 cmt-28 cpb-28">
                {userType === "0" && (
                  <div className="text-14-400 grey-border d-flex align-items-center gap-2 ps-2 pe-2">
                    <CheckBox
                      type="PRIMARY-ACTIVE"
                      onClick={() => {
                        setIsLoading(true);
                        let newData = { ...filterData, ...searchPayload };
                        newData = {
                          ...newData,
                          is_non_allocated_resource:
                            filterData.is_non_allocated_resource ? "" : "1",
                        };
                        setFilterData(newData);
                        getProfiles(newData);
                      }}
                      isChecked={filterData.is_non_allocated_resource === "1"}
                    />
                    <div>Not Allocated Resource</div>
                  </div>
                )}
                <ExportButton
                  exportAPI={exportProfile}
                  payload={objectToFormData({
                    ...filterData,
                    ...searchPayload,
                    ...newDates,
                  })}
                  isFilter={
                    !(
                      (searchPayload?.start_date && searchPayload?.end_date) ||
                      searchPayload?.join_date ||
                      searchPayload?.valid_date
                    )
                  }
                  setFilterDates={setFilterDates}
                  filterDates={filterDates}
                />
                <div>
                  <FilterDropdown
                    list={membershipList}
                    handelChangeFilter={handelChangeFilter}
                    isApplyButton
                  />
                </div>
                <Button
                  isSquare
                  text="+ Add Member"
                  btnStyle="primary-outline"
                  className="h-35 text-14-500 text-nowrap"
                  onClick={() => {
                    setAddMember(!isAddMember);
                  }}
                />
              </div>
            )}
          </div>
        )}

        <div className="w-100 overflow-auto">
          <Table
            isLoading={isLoading}
            header={header}
            rowData={rowData}
            filterData={filterData}
            searchPayload={searchPayload}
            searchInputChange={handelChangeSearch}
            changeOffset={handelChangePagination}
            isOtherPagination
          />
        </div>
      </Card>
    </>
  );
};

export default AllProfiles;
